.container {
    margin-top: 10rem;
    position: relative;
}

.container::before {
    content: "";
    position: absolute;
    background-color: #aaaaaa;
    width: 100%;
    height: 2px;
    top: -1rem;
}