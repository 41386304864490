.card {
    composes: mv5 flex flex-column flex-row-ns from 'components/style/tachyons.css';
    height: 20rem;
}

.textWrapper {
    composes: flex flex-column justify-center mh6-ns mv3 tl w-100 w-50-ns black from 'components/style/tachyons.css';
}

.title {
    composes: f3 fw2 f2-ns mb2 tl from 'components/style/tachyons.css';
}

.desc {
    composes: fw4 f4-ns tl from 'components/style/tachyons.css';
}

.image {
    composes: h-100 w-100 w-50-ns shadow-3 from 'components/style/tachyons.css';
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}