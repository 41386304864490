.container {
    composes: flex flex-column flex-row-ns shadow-2 from 'components/style/tachyons.css';
    height: 30rem;
    background-color: #3e281270;
}

.headerWrapper {
    composes: flex flex-column justify-center items-center black w-50-ns w-100 pv5 from 'components/style/tachyons.css';
}

.header {
    composes: f2 f-5-ns fw3 mv2 from 'components/style/tachyons.css';
}

.subHeader {
    composes: f5 f2-ns fw2 from 'components/style/tachyons.css';
}

.buildings {
    composes: w-50-ns w-100 h-100 from 'components/style/tachyons.css';
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../assets/buildings.png");
}