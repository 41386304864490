.container {
    composes: pv5 ph4 pv6-ns ph6-ns from 'components/style/tachyons.css';
}

.aboutUsSection {
    composes: mt5 from 'components/style/tachyons.css';
}

.aboutUsTitle {
    composes: relative tc center f2 fw3 mv5 from 'components/style/tachyons.css';
    width: fit-content;
}

.aboutUsTitle::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 3px;
    background-color: black;
    left: 10%;
    bottom: -1rem;
}